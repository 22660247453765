.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above{
  background-color: rgb(var(--color-white));
}
.select2-container--navigate{
  .select2-results__option--highlighted[aria-selected]{
    background-color: rgb(var(--color-light-gray)) !important;
  }
  .select2-results__option[aria-selected=true]{
    background-color: rgb(var(--color-light-gray));
    opacity: 0.8;
  }
  .select2-selection--single{
    border-radius: 4px;
  }
  &.select2-container--open{
    .select2-selection--single{
      border-radius: 4px;
    }
    .select2-selection__arrow{
      b{
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
      }
    }
  }
  span.select2-search{
    input {
      height: 30px !important;
    }
  }
  .select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow: scroll;
  }
}

.select2-container{
  .select2-choice {
    border: 2px solid rgb(var(--color-mid-gray));
    height: 36px;
    border-radius: 0px;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    text-indent: 1px;
    box-shadow: none;

    div {
      border-left: 2px solid rgb(var(--color-mid-dark-gray));
      border-radius: 0 4px 4px 0;
    }
    .select2-arrow{
      border: 0px;
      border-radius: 0px;
      background-color: transparent;
    }
  }
  *:focus{
    outline:0px;
  }
  &.select2-drop-above .select2-choice {
    border-bottom-color: rgb(var(--color-mid-gray));
    border-radius:0px;
  }
}

.select2-drop {
  margin-top: -2px;
  border: 2px solid rgb(var(--color-mid-dark-gray));
  border-top: 0;
  border-radius: 0px !important;
  border-radius:0 0 6px 6px;
  box-shadow: none;
  &.select2-drop-above {
    margin-top: 2px;
    border-top: 2px solid rgb(var(--color-mid-dark-gray));
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    box-shadow: none;
  }
}

.select2-search{
  margin-top: 3px;
  input {
    &:focus {box-shadow: none;}
    height: 33px;
    border: 2px solid rgb(var(--color-mid-dark-gray));
  }
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 2px solid rgb(var(--color-mid-dark-gray));
  outline: none;
  box-shadow: none;
}

.select2-dropdown-open .select2-choice {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  .select2-choice div {
    background-color: transparent;
    border-left: none;
    filter: none;
  }
}

.select2-results{
  padding: 0 0 0 0px;
  margin: 4px 0px 0px 0;
  .select2-highlighted {
    background-color: rgb(var(--color-light-gray));
    color: rgb(var(--color-white));
    border-radius: 0px;
  }
}

.select2-container-multi{
  .select2-choices {
    height: auto !important;
    height: 1%;

    border: 2px solid rgb(var(--color-mid-dark-gray));
  }
  &.select2-container-active .select2-choices {
    border: 2px solid rgb(var(--color-mid-dark-gray));
    border-radius: 6px;
    box-shadow: none;
  }
}

/****** Single SCSS *******/
.select2-container--navigate{
  .select2-selection--single{
    @apply w-full border rounded-4px border-mid-gray bg-white px-2 py-3 text-black placeholder:text-opacity-75 active:border-primary focus:border-black disabled:bg-mid-light-gray disabled:text-dark-gray;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select2-selection__rendered{
      color: rgb(var(--color-black));
    }
    .select2-selection__arrow{
      position: relative;
      b {
        display: block;
        border-color: rgb(var(--color-black)) transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        width: 0;
      }
    }
    .select2-selection__placeholder {
      color: rgb(var(--color-black));
    }
    .select2-selection__arrow b {

    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }
  }
}

/****** Multiple SCSS *******/
.select2-container--navigate{
  .select2-selection--multiple{
    border: 1px solid rgb(var(--color-mid-gray));
    border-radius: 4px;
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
    }
    .select2-selection__choice{
      background-color: rgb(var(--color-white));
      border: 2px solid rgb(var(--color-black));
      border-radius: 25px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 9px;
      padding: 2px 15px;
      font-size: 14px;
      line-height: 130%;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .select2-selection__choice__remove{
      font-size: 23px;
      line-height: 18px;
      order: 2;

      &:hover {
        cursor: pointer;
        color: rgb(var(--color-darkest-gray));
      }
    }
  }
  .select2-selection--multiple:before {
      content: "";
      position: absolute;
      right: 7px;
      top: 42%;
      border-top: 5px solid rgb(var(--color-black));
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
  }
}
.select2-container--navigate.select2-container--focus .select2-selection--multiple {
  border: 1px solid rgb(var(--color-black));
}

.select2-container--navigate.select2-container--disabled {
  .select2-selection--single {
    @apply bg-mid-light-gray text-dark-gray;
  }
}

/* react-select/async */
.recipient-option {
  display: flex;
}

/* Kind of silly, but this works with modals */
.select2-container--open{
  z-index:9999999
}
