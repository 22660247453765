#tracker {
  ul[role=tablist] > li.active {
    @apply bg-primary text-secondary
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-content > .active {
    display: block
  }
}
